import React from 'react'

import Hero from '../components/hero'

import { useTranslation } from 'react-i18next'
import VideoPlayer from '../components/video-player'

function OurProjects(props) {
  const { t } = useTranslation()
  return (
    <div className='inner-page'>
      <Hero
        title='Our Projects'
        desc='Our Projects'
        sub='Learning by playing on a platform with its own currency and engaging gamification process'
        i18n={t}
      />

      <VideoPlayer
        url='https://youtu.be/2o8VQzjAZZ0'
        title='Our Projects'
        subtitle='The essence of the School of Education of the Future is to produce human solutions through technology. We open a connection with a universe of possibilities through gamification, artificial intelligence, among others. This is our spirit, our way of inspiring new generations.'
        i18n={t}
        button='Learn More'
      />
    </div>
  )
}

export default OurProjects
