import React from 'react'

import PageTitle from '../components/pagetitle'
import { useTranslation } from 'react-i18next'
import TwoVideoPlayers from '../components/video-player/TwoVideoPlayers'

function Videos(props) {
  const { t } = useTranslation()
  return (
    <div className='inner-page'>
      <PageTitle title='Videos' title_='Follow some of our projects' />

      <section className='tf-section pt60'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <TwoVideoPlayers
                firstSubTitle='Instituto Edufuturo São João da Escócia'
                secondSubTitle='Um dia no Instituto Edufuturo - Caruaru-PE'
                urlFirstVideo='https://youtu.be/wWTPRYUKaYw'
                urlSecondVideo='https://youtu.be/CYJ_uiLutHo'
                button_url={props.linkRegister}
                i18n={t}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Videos
