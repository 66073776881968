import img1 from '../images/slider/Furore.png'
import img2 from '../images/slider/icon_1.png'
import img3 from '../images/slider/icon_2.png'
import img4 from '../images/slider/icon_3.png'

const dataBanner = [
  {
    id: 1,
    heading: "How much is your child's future worth?",
    sub_heading: 'Take Your Skills To The Next Level!',
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
  },
  {
    id: 2,
    heading: 'Enter the gateway of Blockchain Gaming',
    sub_heading:
      'Visually and spatially connecting games in a seamless metaverse experience',
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
  },
]

export default dataBanner
