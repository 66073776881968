const br = {
  translation: {
    Login: 'Entrar',
    Trails: 'Trilhas',
    Contact: 'Contato',
    About: 'Sobre',
    Home: 'Início',
    Address: 'Endereço',
    Phone: 'Telefone',
    Support: 'Suporte',
    Subject: 'Assunto',
    Message: 'Mensagem',
    Videos: 'Vídeos',
    Partners: 'Parceiros',
    Games: 'Jogos',
    Reports: 'Relatórios',
    Donations: 'Doações',
    'Follow some of our projects': 'Acompanhe alguns de nossos projetos',
    'Make a Donation to Edufuturo Institute!':
      'Faça uma doação ao Instituto Edufuturo!',
    'Partners of the Edufuturo Institute': 'Parceiros do Instituto Edufuturo',
    'Youth Training': 'Formação de Jovens',
    'Early Childhood': 'Primeira Infância',
    'Quality Education': 'Educação de Qualidade',
    'Art and Culture': 'Arte e Cultura',
    'What Do We Do?': 'O Que Fazemos?',
    'Who Are We?': 'Quem somos?',
    'Send us a message!': 'Manda uma mensagem pra gente!',
    'Questions, suggestions, criticisms?': 'Dúvidas, sugestões, críticas?',
    'Access platform': 'Acessar plataforma',
    "How much is your child's future worth?":
      'Quanto Vale o Futuro Do Seu Filho?',
    'Make a register!': 'Faça sua matrícula!',
    'Learn More': 'Saiba Mais',
    'Full name': 'Nome completo',
    'Email address': 'Email',
    'Contact us': 'Contato',
    'Business + Entrepreneurship': 'Negócios + Empreendedorismo',
    'Quick Link': 'Links Rápidos',
    'Us In The Media': 'Edufuturo na mídia',
    'Our Connections': 'Nossas conexões',
    'Unlock Your Potential': 'Desbloqueie seu potencial',
    'Get to know Edufuturo': 'Conheça a Edufuturo',
    'The End Of Complexity': 'O Fim Da Complexidade',
    'How To Prepare For The Unexpected?': 'Como se preparar para o inesperado?',
    'The Courses': 'Os Cursos',
    'The Classes': 'As Aulas',
    'The Students': 'Os Estudantes',
    'The Project': 'O Projeto',
    'The Follow-up': 'O Acompanhamento',
    'Creative Design': 'Design Criativo',
    'Music Production': 'Produção Musical',
    'Your name': 'Nome completo',
    'Your email': 'Seu email',
    'Your phone': 'Seu telefone',
    'Enter the subject': 'Assunto',
    'Get in touch': 'Entre em contato',
    'I Want To Know More': 'Quero saber mais',
    'Our Actions': 'Nossas Ações',
    'Our History': 'Nossa História',
    'The Institute': 'O Instituto',
    'Our purpose': 'Nosso Propósito',
    'Our Performance': 'Nossas Atuações',
    'Our Projects': 'Nossos Projetos',
    'What We Do': 'O que fazemos?',
    'Any questions left? Contact us!':
      'Ficou alguma dúvida? Entre em contato conosco!',
    'Our purpose is to offer an education that goes beyond traditional classrooms.':
      'Nosso propósito é oferecer uma educação que vá além das salas de aula tradicionais.',
    'Type your message': 'Digite a sua mensagem',
    'Robotics + Engineering': 'Robótica + Engenharia',
    'Computer Science + Artificial Intelligence':
      'Ciência Da Computação + Inteligência Artificial',
    'Few things are more rewarding than building something for yourself. Throughout the course, students will learn how to build and program robots using Arduino.':
      'Poucas coisas são mais gratificantes do que construir algo para si mesmo. ao longo do curso, os alunos aprenderão como construir e programar robôs usando o Arduino.',
    'Designed by Edufuturo': 'Desenvolvido pela Edufuturo',
    'Learning by playing on a platform with its own currency and engaging gamification process':
      'Aprender brincando em uma plataforma com moeda própria e processo de gamificação envolvente',
    'Awakening A New Consciousness In The World':
      'Despertando Uma Nova Consciência No Mundo',
    'Applied technology': 'Tecnologia aplicada',
    'Proven track record': 'Histórico comprovado',
    'Broad learning experiences': 'Amplas experiências de aprendizagem',
    'American Teaching Methodology': 'Metodologia De Ensino Americana',
    'Learn while playing on a platform with its own currency and an engaging gamification process':
      'Aprender brincando em uma plataforma com moeda própria e processo de gamificação envolvente',
    'Methodology Used by Educational In Numbers':
      'Metodologia Utilizada Pelo Edufuturo Em Números',
    'Unleash Your Potential And Master Your Skills':
      'Libere Seu Potencial E Domine Suas Habilidades',
    'Methodology Used by Edufuturo In Numbers':
      'Metodologia Utilizada pelo Edufuturo Em Números',
    'Our focus on project-based learning gives students experience with practical applications and real-world problem solving.':
      'Nosso foco no aprendizado baseado em projetos oferece aos alunos experiência com aplicações práticas e resolução de problemas do mundo real.',
    'Our courses are made up of annual knowledge tracks, that is, the student will study for a year taking several courses according to the chosen track. These trails are structured according to age group, being divided into two: - EDUfuturo Start Kids Trail for children between 8 and 11 years old; - EDUfuturo Start Teen Trail for teenagers aged 11 to 18.':
      'Nossos cursos são compostos por trilhas de conhecimento anuais, ou seja, o aluno estudará durante um ano realizando diversos cursos de acordo com a trilha escolhida. Estes percursos estão estruturados por faixa etária, sendo divididos em dois: - Trilho EDUfuturo Start Kids para crianças entre os 8 e os 11 anos; - EDUfuturo Start Teen Trail para adolescentes de 11 a 18 anos.',
    "Today's young people must be prepared to thrive in an ever-evolving technology landscape. Developing valuable life skills, revolutionary learning experiences that are sought after in today's job market around the world, align BUSSINESS PRO, REAL SKILLS, HARD SKILLS and in fact, should encompass the concept of CORE SKILLS, neither Hard nor Soft, a mix everything.":
      'Os jovens de hoje devem estar preparados para prosperar em um cenário tecnológico em constante evolução. Desenvolver habilidades valiosas para a vida, experiências de aprendizagem revolucionárias que são procuradas no mercado de trabalho atual do mundo todo, alinham BUSSINESS PRO, REAL SKILLS, HARD SKILLS e na verdade, devem englobar o conceito de CORE SKILLS, nem Hard nem Soft, um misture tudo.',
    'Classes will take place in the morning, afternoon and evening, with one class per week lasting 1:30 hours, and classes of a maximum of 14 students. All classes are accompanied by a bilingual Teacher of the Future who will lead the classes introducing English in a didactic way with real and practical project applications.':
      'As aulas irão acontecer nos turnos da manhã, tarde e noite, sendo uma aula por semana com 1:30h de duração, e turmas de no máximo 14 alunos. Todas as aulas são acompanhadas por um Professor do Futuro bilíngue que conduzirá as aulas introduzindo o inglês de forma didática com aplicações de projetos reais e práticos.',
    'Students will have access to their achievements, scores, badges, avatar and all teaching material for the course that will be made available through our fully gamified EAD online content platform where the student will access video classes, support material, quizzes, questionnaires and all course materials.':
      'Os alunos terão acesso a suas conquistas, pontuações, emblemas, avatar e todo material didático do curso que será disponibilizado através da nossa plataforma EAD de conteúdo online totalmente gamificada onde o aluno vai acessar as videoaulas, material de apoio, quiz, questionários e todos os materiais do curso.',
    'At the end of each course, students will have to create a project and present it to the class. The project will also be documented and submitted on the platform following the methodology standards.':
      'Ao final de cada curso, os alunos terão que criar um projeto e apresentá-lo à turma. O projeto também será documentado e enviado à plataforma de acordo com as normas metodológicas.',
    "Parents and guardians will be able to monitor the student's development through reports that will be sent explaining their progress in detail and also through our online platforms.":
      'Os pais e responsáveis ​​poderão acompanhar todo o desenvolvimento do aluno através de relatórios que serão enviados explicando o progresso deles em detalhes e também através de nossas plataformas online.',
    'A school that surprises at every opportunity':
      'Uma escola que surpreende a cada oportunidade',
    'Schools must focus on being “complete schools”, on real transformation, they must be schools that surprise at every opportunity. Children and teenagers truly ready for lifes challenges.':
      'Escolas devem focar em serem “complete School”, em transformação de verdade, elas devem ser escolas que surpreendam a cada oportunidade. Crianças e Adolescentes verdadeiramente prontas para os desafios da vida.',
    'Our methodology is taught in world-class schools in more than 125 countries and has been verified by numerous independent researchers, associations and universities.':
      'Nossa metodologia é ensinada em escolas de nível mundial em mais de 125 países e foi verificada por vários pesquisadores, associações e universidades independentes.',
    'Prepare students for careers in Game Design, Computer Science, Music Production, Filmmaking, Creative Design, Digital Storytelling, and Entrepreneurship.':
      'Prepare os alunos para carreiras em Game Design, Computer Science, Music Production, Filmmaking, Creative Design, Digital Storytelling, and Entrepreneurship.',
    'Schools must focus on being a “Complete School”, on real transformation, they must be schools that surprise at every opportunity. Children and teenagers really ready for lifes challenges.':
      'As escolas devem focar em ser “Escola completa”, em transformação de verdade, elas devem ser escolas que surpreendam a cada oportunidade. Crianças e Adolescentes realmente prontas para os desafios da vida.',
    'Enroll your child now and be part of this transformation for the future.':
      'Matricule seu filho agora mesmo e faça parte dessa transformação para o futuro.',
    'The essence of the School of Education of the Future is to produce human solutions through technology. We open a connection with a universe of possibilities through gamification, artificial intelligence, among others. This is our spirit, our way of inspiring new generations.':
      'A essência da Escola de Educação do Futuro é produzir soluções humanas por meio da tecnologia. Abrimos uma conexão com um universo de possibilidades através da gamificação, inteligência artificial, entre outros. Este é o nosso espírito, a nossa forma de inspirar as novas gerações.',
    'Entrepreneurs are made, not born. This course was developed and designed in coordination with business school educational developers, industry consultants, and students.':
      'Os empreendedores são feitos, não nascem. Este curso foi desenvolvido e projetado em coordenação com desenvolvedores educacionais de escolas de negócios, consultores da indústria e alunos.',
    'Students will begin by learning and sharing key thematic styles used by social content creators across multiple platforms. Students will learn the methods used by creators to publish different types of video and photo content on the social media platforms YouTube, TikTok, and Instagram.':
      'Os alunos começarão aprendendo e compartilhando os principais estilos temáticos utilizados por criadores de conteúdo social em várias plataformas. Os alunos aprenderão os métodos usados ​​pelos criadores para publicar diferentes tipos de conteúdo de vídeo e foto nas redes sociais YouTube, TikTok e Instagram.',
    'Students will create their own artificial intelligence, such as chatbots, natural language processing, and recommendation systems. Use AI tools like machine learning to create intelligent programs like chatbots from scratch.':
      'Os alunos criarão sua inteligência artificial própria, como chatbots, processamento de linguagem natural e sistemas de recomendação. Use ferramentas de IA, como aprendizado de máquina, para criar programas inteligentes como chatbots do zero.',
    'This course offers a great introduction to game design principles, including interactive storytelling, character creation, and publishing. Our hands-on, design-thinking approach encourages students to unleash their inner designer as well as work collaboratively with other students.':
      'Este curso oferece uma ótima introdução aos princípios de design de jogos, incluindo narrativa interativa, criação de personagens e publicação. Nossa abordagem prática e de pensamento de design incentiva os alunos a liberar seu designer interno, bem como a trabalhar em colaboração com outros alunos.',
    'Discover how animators at Pixar, Cartoon Network, and Disney produce cartoons and films by understanding the principles of life drawing. Develop the art of storytelling by thinking sequentially to create storyboards.':
      'Descubra como os animadores da Pixar, Cartoon Network e Disney produzem desenhos animados e filmes compreendendo os princípios do desenho de vida. Desenvolva a arte de contar histórias pensando sequencialmente para criar storyboards.',
    'Students learn the science of sound and synthesis and how to loop musical ideas in the Ableton Live session view. Students will arrange and produce an existing popular song and learn the fundamentals of music production.':
      'Os alunos aprendem a ciência do som e da síntese e como criar um loop de ideias musicais na visualização da sessão do Ableton Live. Os alunos terão seus próprios arranjos e produção de uma canção popular existente e aprenderão os fundamentos da produção musical.',
    "Our mentor educators are experts in their fields, committed to inspiring and guiding students on their journey of discovery and growth. A highly qualified team to ensure quality education, focused on developing essential skills for the global job market. Here, we also understand the importance of the partnership between school and family, which is why we offer an efficient and transparent communication system. This way, you will always be involved in your child's educational and behavioral process, closely monitoring their development and progress.":
      'Nossos educadores mentores são especialistas em suas áreas, comprometidos em inspirar e orientar os estudantes em sua jornada de descoberta e crescimento. Um super time altamente qualificado para garantir um ensino de qualidade, focado no desenvolvimento de habilidades essenciais para o mercado de trabalho global. Aqui, nós também entendemos a importância da parceria entre a escola e a família, por isso, oferecemos um sistema de comunicação eficiente e transparente. Assim, você estará sempre envolvido no processo educacional e comportamental de seu filho, acompanhando de perto seu desenvolvimento e progresso.',
    'In an integrated manner with government agencies, private institutions and the community, the Conceição Moura Institute promotes projects and initiatives that foster the development of the potential and capabilities of children and young people, supporting them in overcoming the challenges of their personal lives and social realities. The Conceição Moura Institute provides tools and methods, in addition to articulating skills, helping to awaken a broader world view, expanding the capacity for achievement and transformation of each of these young people. In general, our work is segmented into four major areas of activity: Early Childhood, Youth Development, Quality Education and Art and Culture.':
      'De forma integrada junto as esferas governamentais, instituições privadas e a comunidade, o Instituto Conceição Moura promove projetos e iniciativas que favorecem o desenvolvimento de potencialidades e capacidades de crianças e jovens, apoiando-os na superação dos desafios da vida pessoal e de suas realidades sociais. O Instituto Conceição Moura fornece ferramentas e métodos, além de articular competências, contribuindo para despertar uma visão de mundo mais ampla, expandindo a capacidade de realização e transformação de cada um desses jovens. De forma geral, nosso trabalho é segmentado em quatro grandes áreas de atuação: Primeira Infância, Formação de Jovens, Educação de Qualidade e Arte e Cultura.',
    'Contribute to the development of children and young people so that they become agents of transformation in the world.':
      'Contribuir com o desenvolvimento de crianças e jovens para que se tornem agentes de transformação no mundo.',
    'We implement Future Classrooms in educational institutions and different sectors, using project-based methodologies, bilingual teaching and international experiences, which promote the development of essential skills for the market and life.':
      'Implantamos Salas do Futuro em instituições de ensino e diferentes setores, utilizando metodologias baseadas em projetos, o ensino bilíngue e experiências internacionais, que promovem o desenvolvimento de habilidades essenciais para o mercado e a vida.',
    'We encourage scientific creativity and logical thinking.':
      'Estimulamos a criatividade científica e o pensamento lógico.',
    'Science and Technology': 'Ciência e Tecnologia',
    'Entrepreneurship and Business': 'Empreendedorismo e Negócios',
    'We train leaders and visionaries': 'Formamos líderes e visionários',
    'Art and Design': 'Arte e Design',
    'We awaken the artistic gaze and aesthetic innovation':
      'Despertamos o olhar artístico e a inovação estética.',
    'Music, Media and Cinema': 'Música, Mídia e Cinema',
    'We combine expression, emotion and technique.':
      'Unimos expressão, emoção e técnica.',
    'We turn fun into learning.': 'Transformamos diversão em aprendizado.',
    'At Instituto Edufuturo, we believe in the power of education as a catalyst to transform lives and prepare children and adolescents for the challenges of the future. We are the Third Sector arm of Edufuturo, an innovative Big Edtech, specialized in Artificial Intelligence and the creation of authentic and immersive learning environments, where wisdom and technology of the future walk hand in hand.':
      'No Instituto Edufuturo, acreditamos no poder da educação como um catalisador para transformar vidas e preparar crianças e adolescentes para os desafios do futuro. Somos o braço do Terceiro Setor da Edufuturo, uma Big Edtech inovadora, especializada em Inteligência artificial e na criação de ambientes de aprendizado autênticos e imersivos, onde a sabedoria e a tecnologia do futuro caminham juntas.',
    'We work with five key areas of knowledge':
      'Trabalhamos com cinco áreas-chave de conhecimento',
    'We awaken the artistic gaze and aesthetic innovation.':
      'Despertamos o olhar artístico e a inovação estética.',
    'We train leaders and visionaries.': 'Formamos líderes e visionários.',
    'The history of the Edufuturo Institute was born from a dream: transforming traditional education into a meaningful experience aligned with the demands of a constantly evolving world. But that this big dream could happen in the communities and outskirts of Brazil.':
      'A história do Instituto Edufuturo nasceu de um sonho: transformar a educação tradicional em uma experiência significativa e alinhada às demandas de um mundo em constante evolução. Mas que esse sonho grande pudesse acontecer nas comunidades e periferias do Brasil. ',
    'At Instituto Edufuturo, we believe in the power of education as a catalyst to transform lives and prepare children and adolescents for the challenges of the future.':
      'No Instituto Edufuturo acreditamos no poder da educação como catalisadora para transformar vidas e preparar crianças e adolescentes para os desafios do futuro.',
    'At Instituto Edufuturo, we think about the power of education as a discovery to transform lives and prepare children and adolescents for the challenges of the future. We are the Third Sector arm of Edufuturo, an innovative Big Edtech, specialized in artificial intelligence and the creation of authentic and immersive learning environments, where wisdom and technology of the future go hand in hand. Our purpose is to offer an education that goes beyond traditional classrooms. We implement Future Rooms in educational institutions and different sectors, using project-based methodologies, bilingual teaching and international experiences, which promote the development of essential skills for the market and life.':
      'No Instituto Edufuturo, pensamos no poder da educação como uma descoberta para transformar vidas e preparar crianças e adolescentes para os desafios do futuro. Somos o braço do Terceiro Setor da Edufuturo, uma Big Edtech inovadora, especializada em inteligência artificial e na criação de ambientes de aprendizado autênticos e imersivos, onde a sabedoria e a tecnologia do futuro caminham juntas. Nosso propósito é oferecer uma educação que vai além das salas de aula tradicionais. Implantamos Salas do Futuro em instituições de ensino e diferentes setores, utilizando metodologias baseadas em projetos, o ensino bilíngue e experiências internacionais, que promovem o desenvolvimento de habilidades essenciais para o mercado e a vida.',
    'At Instituto Edufuturo, we believe in the power of education as a catalyst to transform lives and prepare children and adolescents for the challenges of the future. We are the Third Sector arm of Edufuturo, an innovative Big Edtech, specialized in Artificial Intelligence and the creation of authentic and immersive learning environments, where wisdom and technology of the future go hand in hand. Our purpose is to offer an education that goes beyond traditional classrooms. We implement Future Rooms in educational institutions and different sectors, using project-based methodologies, bilingual teaching and international experiences, which promote the development of essential skills for the market and life.':
      'No Instituto Edufuturo, acreditamos no poder da educação como um catalisador para transformar vidas e preparar crianças e adolescentes para os desafios do futuro. Somos o braço do Terceiro Setor da Edufuturo, uma Big Edtech inovadora, especializada em Inteligência artificial e na criação de ambientes de aprendizado autênticos e imersivos, onde a sabedoria e a tecnologia do futuro caminham juntas. Nosso propósito é oferecer uma educação que vai além das salas de aula tradicionais. Implantamos Salas do Futuro em instituições de ensino e diferentes setores, utilizando metodologias baseadas em projetos, o ensino bilíngue e experiências internacionais, que promovem o desenvolvimento de habilidades essenciais para o mercado e a vida.',
    'Students at the Edufuturo Institute, affectionately called Transformers, embark on an immersive and collaborative learning journey, where they develop technical, emotional and social skills. We believe that learning is celebrating achievements, and that is why we promote events to celebrate learning, which reinforce our values ​​and commitment to the future. In addition, we offer international certification and exchange programs that connect our students to the world, bringing a global experience to Brazil. Our mission is to be a reference in cutting-edge education, positively impacting lives, families and communities. Welcome to the Edufuturo Institute, where we train the protagonists of a world in constant transformation!':
      'Os alunos do Instituto Edufuturo, chamados carinhosamente de Transformeiros, embarcam em uma jornada de aprendizado imersiva e colaborativa, onde desenvolvem habilidades técnicas, emocionais e sociais. Acreditamos que aprender é celebrar conquistas, e por isso promovemos eventos de celebração do aprendizado, que reforça nossos valores e compromissos com o futuro. Além disso, oferecemos certificação internacional e intercâmbios que conectam nossos alunos ao mundo, trazendo uma vivência global para dentro do Brasil. Nossa missão é ser referência em educação de ponta, impactando positivamente vidas, famílias e comunidades. Seja bem-vindo ao Instituto Edufuturo, onde formamos os protagonistas de um mundo em constante transformação!',
  },
}
export default br
