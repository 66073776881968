import React from 'react'

import Hero from '../components/hero'

import { useTranslation } from 'react-i18next'
import Project from '../components/project/project_v8'
import dataProject from '../assets/fake-data/data-project'
import Knowledge from '../components/project/project_v9'

function TheInstitute(props) {
  const { t } = useTranslation()
  return (
    <div className='inner-page'>
      <Hero
        title='The Institute'
        desc='The Institute'
        sub='At Instituto Edufuturo, we believe in the power of education as a catalyst to transform lives and prepare children and adolescents for the challenges of the future.'
        i18n={t}
      />

      <Project
        title='Our purpose'
        title2='Our purpose is to offer an education that goes beyond traditional classrooms.'
        data={dataProject}
        button_url={props.linkRegister}
        i18n={t}
        desc='At Instituto Edufuturo, we believe in the power of education as a catalyst to transform lives and prepare children and adolescents for the challenges of the future. We are the Third Sector arm of Edufuturo, an innovative Big Edtech, specialized in Artificial Intelligence and the creation of authentic and immersive learning environments, where wisdom and technology of the future go hand in hand. Our purpose is to offer an education that goes beyond traditional classrooms. We implement Future Rooms in educational institutions and different sectors, using project-based methodologies, bilingual teaching and international experiences, which promote the development of essential skills for the market and life.'
      />

      <Knowledge
        data={dataProject}
        title='We work with five key areas of knowledge'
        subtitle='Schools must focus on being “complete schools”, on real transformation, they must be schools that surprise at every opportunity. Children and teenagers truly ready for lifes challenges.'
        button_url={props.linkRegister}
        i18n={t}
      />

      <Project
        data={dataProject}
        button_url={props.linkRegister}
        i18n={t}
        desc='Students at the Edufuturo Institute, affectionately called Transformers, embark on an immersive and collaborative learning journey, where they develop technical, emotional and social skills. We believe that learning is celebrating achievements, and that is why we promote events to celebrate learning, which reinforce our values ​​and commitment to the future.

In addition, we offer international certification and exchange programs that connect our students to the world, bringing a global experience to Brazil. Our mission is to be a reference in cutting-edge education, positively impacting lives, families and communities.

Welcome to the Edufuturo Institute, where we train the protagonists of a world in constant transformation!'
      />
    </div>
  )
}

export default TheInstitute
