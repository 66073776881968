import React from 'react'

import Hero from '../components/hero'

import { useTranslation } from 'react-i18next'
import VideoPlayer from '../components/video-player'
import Project from '../components/project/project_v1'
import dataProject from '../assets/fake-data/data-project'

function OurActions(props) {
  const { t } = useTranslation()
  return (
    <div className='inner-page'>
      <Hero
        title='Our Actions'
        desc='Our Actions'
        sub='Learning by playing on a platform with its own currency and engaging gamification process'
        i18n={t}
      />

      <VideoPlayer
        url='https://youtu.be/2o8VQzjAZZ0'
        title='Our Actions'
        subtitle='O Instituto Conceição Moura é uma organização de investimento social privado, criada em 2014 e mantida pelo Grupo Moura. Nasceu para dar continuidade ao trabalho social desenvolvido por Conceição Moura, Co-fundadora da Baterias Moura, nos anos 90. Com sede em Belo Jardim, o Instituto atua com projetos sociais com foco em crianças e jovens, estimulando o protagonismo e o desenvolvimento das pessoas.'
        i18n={t}
        button='Learn More'
      />

      <Project
        title='Our Mission'
        title2='Our Mission'
        data={dataProject}
        button_url={props.linkRegister}
        i18n={t}
        desc='Contribute to the development of children and young people so that they become agents of transformation in the world.'
      />
    </div>
  )
}

export default OurActions
