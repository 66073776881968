import React from 'react'

import { useState } from 'react'

import '@vidstack/react/player/styles/base.css'
import '@vidstack/react/player/styles/plyr/theme.css'

import { MediaPlayer, MediaProvider } from '@vidstack/react'
import {
  PlyrLayout,
  plyrLayoutIcons,
} from '@vidstack/react/player/layouts/plyr'
import Button from '../button'

function VideoPlayer(props) {
  const [dataTitle] = useState({
    title: props.title,
    subtitle: props.subtitle,
    url: props.url,
  })
  return (
    <section className='tf-section tf_CTA'>
      <div className='container relative'>
        <div className='overlay'></div>
        <div className='row'>
          <div className='col-md-6'>
            <div
              className='tf-title left'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <h2 className='title'>{props.i18n(dataTitle.title)}</h2>
              <p className='sub'>{props.i18n(dataTitle.subtitle)}</p>
              <div className='wrap-btn btn-desk'>
                <Button
                  title={props.i18n('Make a register!')}
                  path='/'
                  addclass='style2'
                />
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className='tf-title left'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <MediaPlayer
                title='Uma escola que surpreende a cada oportunidade.'
                src={dataTitle.url}
              >
                <MediaProvider />
                <PlyrLayout icons={plyrLayoutIcons} />
              </MediaPlayer>
            </div>
            <div className='wrap-btn btn-mobile'>
              <Button
                title={props.i18n(props.button)}
                path='/'
                addclass='style2'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoPlayer
