const menus = [
  {
    id: 1,
    name: 'Home',
    links: '/',
  },
  {
    id: 2,
    name: 'About',
    links: '#',
    namesub: [
      {
        id: 1,
        sub: 'Our History',
        links: '/history',
      },
      {
        id: 2,
        sub: 'The Institute',
        links: '/institute',
      },
      // {
      //   id: 2,
      //   sub: 'Reports',
      //   links: '/reports',
      // },
    ],
  },
  // {
  //   id: 3,
  //   name: 'What We Do',
  //   links: '#',
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: 'Our Performance',
  //       links: '/actions',
  //     },
  //     {
  //       id: 2,
  //       sub: 'Our Projects',
  //       links: '/projects',
  //     },
  //   ],
  // },
  {
    id: 4,
    name: 'Videos',
    links: '/videos',
  },
  {
    id: 5,
    name: 'Donation',
    links: '/donation',
  },
  // {
  //   id: 5,
  //   name: 'Partners',
  //   links: 'partners',
  // },
  // {
  //   id: 6,
  //   name: 'Contact',
  //   links: 'contact',
  // },
]

export default menus
