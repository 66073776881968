import React from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Banner(props) {
  const { item } = props
  const { t } = useTranslation()
  return (
    <div
      className='project-box-style2'
      data-aos='fade-up'
      data-aos-delay='100'
      data-aos-duration='800'
    >
      <div className='image'>
        <img src={item.img} alt='' />
      </div>
      <div className='content'>
        <h5>{t(item.title)}</h5>
        <p className='desc'>{t(item.desc)}</p>
        <p className='number'>{t(item.number)}</p>
      </div>
      <Link to='#' className='btn_project'>
        <span>
          <i className='far fa-angle-right'></i>
        </span>
      </Link>
    </div>
  )
}

export default Banner
