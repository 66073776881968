import React from 'react'

import Hero from '../components/hero'

import { useTranslation } from 'react-i18next'
import Project from '../components/project/project_v10'
import dataProject from '../assets/fake-data/data-project'

function OurHistory(props) {
  const { t } = useTranslation()
  return (
    <div className='inner-page'>
      <Hero
        title='Our History'
        desc='Our History'
        sub='The history of the Edufuturo Institute was born from a dream: transforming traditional education into a meaningful experience aligned with the demands of a constantly evolving world. But that this big dream could happen in the communities and outskirts of Brazil.'
        i18n={t}
      />

      <Project
        title='Our purpose'
        title2='Our purpose is to offer an education that goes beyond traditional classrooms.'
        data={dataProject}
        video_url='https://youtu.be/2o8VQzjAZZ0'
        i18n={t}
        desc={`A história do Instituto Edufuturo nasceu de um sonho: transformar a educação tradicional em uma experiência significativa e alinhada às demandas de um mundo em constante evolução. Mas que esse sonho grande pudesse acontecer nas comunidades e periferias do Brasil. Fundado com a missão de unir sabedoria e tecnologia, o Instituto surgiu como uma resposta à necessidade de preparar crianças e adolescentes não apenas para o mercado, mas para a vida.  
          Desde o início, nos dedicamos a criar ambientes de aprendizado inovadores, incorporando metodologias de ensino baseadas em projetos, ensino bilíngue e experiências internacionais. Nosso compromisso é com a formação de cidadãos globais e protagonistas do futuro. Inspirados por uma visão de impacto positivo, expandimos nossas operações, implantando as revolucionárias Salas do Futuro em instituições e setores, sempre com foco em oferecer uma educação que une inovação e propósito.
          Ao longo dessa trajetória, consolidamos áreas de conhecimento como Ciência e Tecnologia, Empreendedorismo, Arte e Design, Música, Mídia, Cinema e Games, promovendo uma formação integral para nossos alunos, os Transformeiros. Eventos de celebração do aprendizado e iniciativas como certificações internacionais e intercâmbios reforçam nosso compromisso com a excelência educacional.
          Hoje, o Instituto Edufuturo é referência em educação de ponta, impulsionando sonhos e construindo um legado de transformação por meio do poder da educação. Nossa história é guiada pela paixão por ensinar e pela certeza de que o futuro começa agora.`}
      />
    </div>
  )
}

export default OurHistory
