import React from 'react'
import PropTypes from 'prop-types'

import '../styles.scss'
import Button from '../../button'

import '@vidstack/react/player/styles/base.css'
import '@vidstack/react/player/styles/plyr/theme.css'

import { MediaPlayer, MediaProvider } from '@vidstack/react'
import {
  PlyrLayout,
  plyrLayoutIcons,
} from '@vidstack/react/player/layouts/plyr'

Project.propTypes = {
  data: PropTypes.array,
}

function Project(props) {
  return (
    <section className='tf-section project'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='text-center'>
              <div className='content-wrapper'>
                <div
                  className='content_inner'
                  data-aos='fade-left'
                  data-aos-duration='1200'
                >
                  <div className='wrapper'>
                    <h3 className='capitalize'>{props.i18n(props.title2)}</h3>
                    <p className='sub-title'>{props.i18n(props.desc)}</p>
                  </div>
                </div>
              </div>
              <div
                className='tf-title left video_history'
                data-aos='fade-up'
                data-aos-duration='800'
              >
                <MediaPlayer
                  title='Uma escola que surpreende a cada oportunidade.'
                  src={props.video_url}
                >
                  <MediaProvider />
                  <PlyrLayout icons={plyrLayoutIcons} />
                </MediaPlayer>
              </div>
              {/* <div className='wrap-btn btn-mobile'>
                <Button
                  title={props.i18n(props.button)}
                  path='/'
                  addclass='style2'
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Project
