import React from 'react'

import PageTitle from '../components/pagetitle'
import imgPIX from '../assets/images/qrcode/pix.jpg'

import { useTranslation } from 'react-i18next'

function Donation(props) {
  const { t } = useTranslation()

  return (
    <div className='inner-page'>
      {
        <PageTitle
          title='Donations'
          title_='Make a Donation to Edufuturo Institute!'
        />
      }

      <section className='tf-section tf-contact pt60'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <ul className='contact-info'>
                <li>
                  <div className='content'>
                    <p>PIX</p>
                    <p className='color-hover'>46.109.314/0001-28</p>
                    <div className='qrcode'>
                      <img src={imgPIX} alt='' />
                    </div>
                  </div>
                </li>

                <li>
                  <div className='content'>
                    <p>Bank</p>
                    <p className='white'>Cora SDC</p>
                    <p className='white'>Agência: 0001</p>
                    <p className='white'>Conta: 2615015-4</p>
                  </div>
                </li>
                <li>
                  <div className='content'>
                    <p>{t('Name')}</p>
                    <p className='white'>
                      Instituto Edufuturo De Educação E Tecnologia
                    </p>
                    <br></br>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='tf-section project-info'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <form className='form-contact' ref={form} onSubmit={sendPeople}> 
              <div className='project-info-form'>
                <h6 className='title'>{t('Contact us')}</h6>
                <div className='form-inner'>
                  <fieldset>
                    <label>{t('Full name')}</label>
                    <input
                      type='text'
                      id='name'
                      placeholder={t('Your name')}
                      required
                      name='name'
                      value={getName}
                      onChange={handleChangeName}
                    />
                  </fieldset>
                  <fieldset>
                    <label>{t('Email address')}</label>
                    <input
                      type='email'
                      id='email'
                      placeholder={t('Your email')}
                      required
                      name='email'
                      value={getEmail}
                      onChange={handleChangeEmail}
                    />
                  </fieldset>
                  <fieldset>
                    <label>{t('Phone')}</label>
                    <input
                      type='number'
                      id='phone'
                      placeholder={t('Your phone')}
                      required
                      name='phone'
                      value={getPhone}
                      onChange={handleChangePhone}
                    />
                  </fieldset>
                  <fieldset>
                    <label>{t('Subject')}</label>
                    <input
                      type='text'
                      id='subject'
                      placeholder={t('Enter the subject')}
                      required
                      name='subject'
                      value={getSubject}
                      onChange={handleChangeSubject}
                    />
                  </fieldset>
                  <fieldset>
                    <label htmlFor='message'>{t('Message')}</label>
                    <textarea
                      id='message'
                      placeholder={t('Type your message')}
                      rows='5'
                      tabIndex='4'
                      name='message'
                      className='message'
                      value={getMessage}
                      onChange={handleChangeMessage}
                      required
                    ></textarea>
                  </fieldset>
                </div>
              </div>

              <div className='wrap-btn'>
                <button
                  type='submit'
                  onClick={sendPeople}
                  className='tf-button style1'
                >
                  {t('Send message')}
                </button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  )
}

export default Donation
