import React from 'react'
import PropTypes from 'prop-types'

import { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import '../styles.scss'

Project.propTypes = {
  data: PropTypes.array,
}

function Project(props) {
  return (
    <section className='tf-section project'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='text-center'>
              <div className='content-wrapper'>
                <div
                  className='content_inner'
                  data-aos='fade-left'
                  data-aos-duration='1200'
                >
                  <div className='wrapper'>
                    <h3 className='capitalize'>{props.i18n(props.title2)}</h3>
                    <p className='desc'>{props.i18n(props.desc)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Project
