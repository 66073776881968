import React from 'react'
import dataBanner from '../assets/fake-data/data-banner'
import Banner3 from '../components/banner/banner_v3'

import { useTranslation } from 'react-i18next'
import Knowledge from '../components/project/project_v9/index.jsx'
import dataProject from '../assets/fake-data/data-project.js'

function HomeThree(props) {
  const { t } = useTranslation()
  return (
    <div className='header-fixed main home3 counter-scroll'>
      {
        <Banner3
          button='Learn More'
          data={dataBanner}
          title='Who Are We?'
          sub='No Instituto Edufuturo, acreditamos no poder da educação como um catalisador para transformar vidas e preparar crianças e adolescentes para os desafios do futuro. Somos o braço do Terceiro Setor da Edufuturo, uma Big Edtech inovadora, especializada em Inteligência artificial e na criação de ambientes de aprendizado autênticos e imersivos, onde a sabedoria e a tecnologia do futuro caminham juntas.'
          i18n={t}
          link='/institute'
        />
      }

      <Knowledge
        data={dataProject}
        title='We work with five key areas of knowledge'
        subtitle='Schools must focus on being “complete schools”, on real transformation, they must be schools that surprise at every opportunity. Children and teenagers truly ready for lifes challenges.'
        button_url={props.linkRegister}
        i18n={t}
      />

      {/* <Connections
        title='Partners of the Edufuturo Institute'
        data={dataConnections}
      /> */}

      {/* <CTA3
        title='Questions, suggestions, criticisms?'
        sub='Send us a message!'
        button='Contact us'
        i18n={t}
      /> */}
    </div>
  )
}

export default HomeThree
