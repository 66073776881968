import HomeThree from './HomeThree'

import Contact from './Contact'

import OurActions from './OurActions'
import OurHistory from './OurHistory'
import TheInstitute from './TheInstitute'
import OurProjects from './OurProjects'
import Videos from './Videos'
import Donation from './Donation'

const onClick = () => {
  window.open('http://localhost:3000/registration', '_blank')
}

const routes = [
  { path: '/', component: <HomeThree linkRegister={onClick} /> },
  { path: '/actions', component: <OurActions linkRegister={onClick} /> },
  { path: '/history', component: <OurHistory linkRegister={onClick} /> },
  { path: '/donation', component: <Donation linkRegister={onClick} /> },
  { path: '/institute', component: <TheInstitute linkRegister={onClick} /> },
  { path: '/videos', component: <Videos linkRegister={onClick} /> },
  { path: '/projects', component: <OurProjects linkRegister={onClick} /> },
  { path: '/contact', component: <Contact linkRegister={onClick} /> },
]

export default routes
